import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import OrdersTable from "../../../../Components/Dashboard_Table/OrdersTable";
import { toast } from "react-toastify";
import { OrdersPageQuery } from "../../../../Operations/Queries";
import { CountryContext } from "../../../..";
import { ordersActionMutation } from "../../../../Operations/Mutations";
import Toaster from "../../../../Components/ToastMessage";
import MobileTable from "../mobileTable/MobileTable";

interface ConfirmedOrderProps {
  menuItems: string[];
  selectedMenuItem: string;
  tabchange:boolean;
}

interface ConfirmedOrderData {
  id: string;
  order: string;
  date: string;
  itemName: string;
  itemPrice: number;
  quantity: number;
  itemTotal: number;
}

const ConfirmedOrder: React.FC<ConfirmedOrderProps> = ({
  menuItems,
  selectedMenuItem,
  tabchange
}) => {
  const [confirmedOrders, setConfirmedOrders] = useState<ConfirmedOrderData[]>([]);
  // const [ordersConfirmedData, setOrdersConfirmedData] = useState<any>();
  const [ordersConfirmedPageInfo, setOrdersConfirmedPageInfo] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState({
    pending: 1,
    confirmed: 1,
    awb: 1,
    shipped: 1,
    delivered: 1,
    cancelled: 1,
    archived: 1,
  });

  const loggedInToken = localStorage.getItem("loggedInCust");
  const [search, setSearch] = useState({
    pending: "",
    confirmed: "",
    awb: "",
    shipped: "",
    delivered: "",
    cancelled: "",
    archived: "",
  });

  const array: any = [
   { lable: "5", value: 5},
   { lable: "10", value: 10},
   { lable: "15", value: 15},
  ]
  const { store } = useContext(CountryContext);

  const [generateAwb] = useMutation(
    ordersActionMutation.ORDER_GENERATE_AWB,
    {
       context: {
          headers: {
             Authorization: loggedInToken
                ? `Bearer ${JSON.parse(loggedInToken)}`
                : "",
             Connection: "keep-alive",
             store: store,
          },
       },
    }
 );

  const { data: OrdersListConfirmedData, refetch: refetchConfirmedOrders,loading:loadingConfirmedOrder } = useQuery(OrdersPageQuery.ORDERS_CONFIRMED, {
    context: {
       headers: {
          Authorization: loggedInToken
             ? `Bearer ${JSON.parse(loggedInToken)}`
             : "",
          Connection: "keep-alive",
          store: store,
       },
    },
    variables: {
      orderNumber: search.confirmed,
      pageSize:pageSize,
      currentPage: currentPage.confirmed,
    },
 });

  const additionalActionsForConfirmed = [
    {
       label: 'Generate AWB',
       buttonLabel: 'Generate AWB',
       onClick: async (row: any) => {
          toast.info('Please wait while processing your request!', {
             position: toast.POSITION.TOP_RIGHT
          });
          // const rowId = row.id;
          const itemId = row.id;
          const orderNumber = row.order;
          const finalPayload = {
             action: "orderAction",
             status: "generate_awb",
             order_number: orderNumber,
             item_id: itemId
          };
          try {
             const response = await generateAwb({
                variables: finalPayload
             });
             if(response?.data?.sellerOrderAction?.order_item_status==="awb_generated") {
                window.open(response?.data?.sellerOrderAction?.file)
             }
             if(response?.data?.sellerOrderAction==null) {
                toast.warning('An AWB will be generated shortly by the Jebelz team, contact customer@jebelz.com', {
                   position: toast.POSITION.TOP_RIGHT
                });
             }else {
                toast.success('Successfully generated AWB', {
                   position: toast.POSITION.TOP_RIGHT
                });
             }
             
             
             // alert(`Order Confirmed for ${orderNumber}`)
             refetchConfirmedOrders();
            //  refetchAwbOrders();
             
          } catch (error) {
             toast.error('Error generating AWB', {
                position: toast.POSITION.TOP_RIGHT
            });
          }
       }
    },
 ];

  useEffect(() => {
    // setOrdersConfirmedData(OrdersListConfirmedData?.sellerOrdersList[0]?.items);
    setOrdersConfirmedPageInfo(OrdersListConfirmedData?.sellerOrdersList[0]?.pageInfo);
    const transformed = OrdersListConfirmedData?.sellerOrdersList[0]?.items?.map((order: any, index: any) => ({
       id: order.item_id,
       order: order.order_number,
       date: order.date,
       itemName: order.product_name,
       itemPrice: order.price,
       quantity: order.qty,
       itemTotal: order.sub_total,
       waybillNumber: order.waybill_no,
       waybillFile: order.waybill_file,
    }));
    setConfirmedOrders(transformed)
 }, [OrdersListConfirmedData]);

  const handlePageChange = (_: any, value: number) => {
    setCurrentPage({ ...currentPage, confirmed: value });
    refetchConfirmedOrders({
      orderNumber: search.confirmed,
      pageSize: 10,
      currentPage: value,
    });
  };

  const handlePageSizeChange = (event: any) => {
    const newSize = event.target.value as number;
    setPageSize(newSize);
    setCurrentPage({ ...currentPage, confirmed: 1 }); 
    refetchConfirmedOrders({
      orderNumber: search.confirmed,
      pageSize: newSize,
      currentPage: 1,
    });
  };

  const handleSearch = () => {
    setCurrentPage({ ...currentPage, confirmed: 1 });
    refetchConfirmedOrders({
      orderNumber: search.confirmed,
      pageSize: 10,
      currentPage: 1,
    });
  };

  useEffect(()=>{
    refetchConfirmedOrders()
    //eslint-disable-next-line
  },[tabchange])

  const headCellsForConfirmed = [
    { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
    { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (AED)' },
    { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
    { id: 'itemTotal', numeric: true, disablePadding: false, label: 'Item Total (AED)' },
 ];

  return (
    <>
      <Toaster />
      <div className="main">
        <div
          className="btn-group"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <TextField
            placeholder="Search by order number"
            name="search_pending_orders"
            id="search_pending_orders"
            value={search.confirmed}
            onChange={(e) => setSearch({ ...search, confirmed: e.target.value })}
            variant="outlined"
            className="search-text-field"
            sx={{
              width: 300,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#888",
                },
              },
            }}
          />
          <Button
            onClick={handleSearch}
            className="search-button"
            sx={{
              height: 50,
              backgroundColor: "#ddd",
              color: "black",
              "&:hover": {
                backgroundColor: "#bbb",
              },
            }}
          >
            <Typography>Search</Typography>
          </Button>
        </div>
        {loadingConfirmedOrder ? (
          <CircularProgress />
        ) : (
          <OrdersTable
            headCells={headCellsForConfirmed}
            rows={confirmedOrders}
            ButtonActions={additionalActionsForConfirmed}
            pageChange={handlePageChange}
            pageType="confirmed"
            pageNumber={ordersConfirmedPageInfo?.current_page}
          />
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pt: 5,
          }}
          className="deskPagination"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2">Items per page:</Typography>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              variant="outlined"
              size="small"
            >
              {array?.map((size: any) => (
                <MenuItem key={size} value={size.value}>
                  {size.lable}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Pagination
            count={Math.ceil(80 / pageSize)}
            shape="rounded"
            page={currentPage.confirmed}
            onChange={handlePageChange}
          />
        </Box>
      </div>
      {menuItems.map(
        (menuItem, index) =>
          selectedMenuItem === menuItem && (
            <div className="mobile-wrapper" key={index}>
              <MobileTable
                headCells={headCellsForConfirmed}
                rows={confirmedOrders}
                ButtonActions={additionalActionsForConfirmed}
                onChange={handlePageChange}
                onRowsPerPageChange={handlePageSizeChange}
                pageSize={pageSize}
                pageIs={currentPage.confirmed}
              />
            </div>
          )
      )}
    </>
  );
};

export default ConfirmedOrder;
