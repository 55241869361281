import { Typography, Box, List, Grid, TextField, Button, Alert, AlertTitle } from '@mui/material'
import React, { useContext, useState } from 'react'
import "../../../../src/mainseller.scss";
import { useMutation } from '@apollo/client';
import * as mutations from "../../../Operations/Mutations";
import { CountryContext } from '../../..';
import Toaster from '../../../Components/ToastMessage';
import { toast } from 'react-toastify';

const generateRandomCaptcha = (): string => {
    
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return captcha;
  };

const ForgotPwd = () => {
    const [captcha, setCaptcha] = useState(generateRandomCaptcha());
    const [userInput, setUserInput] = useState('');
    const [forgotPwd, setForgotPwd] = useState({ email: ""})
    const [resError, setResError] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const loggedInToken = localStorage.getItem("loggedInCust");

    const {store} = useContext(CountryContext);

    let [passwordResetRequest] = useMutation(
        mutations.forgotPwdMutation.FORGOTPWD_MUTATION,
        {
            context: {
                headers: {
                    Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
                    Connection: "keep-alive",
                    store: store,
                },
            },
        }
    );
    
    const handleRefresh = () => {
        setCaptcha(generateRandomCaptcha());
        setUserInput('');
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(e.target.value);
      };

      //eslint-disable-next-line
      const handleSubmit = () => {
        if (userInput === captcha) {
          alert('Captcha Matched!');
        } else {
          alert('Incorrect Captcha. Please try again.');
        }
      };

    const handleForgotPwdRequest = async (event: any) => {
        console.log("pwdEmail", forgotPwd.email )
        event.preventDefault();
        if (userInput !== captcha) {
            alert('Incorrect Captcha. Please try again.');
            return;
          }
        const data = forgotPwd;
        try {
            toast.info('Wait while your request is under process', {
                position: toast.POSITION.TOP_RIGHT
            });
            const response = await passwordResetRequest({
                variables: data,
            });
            const result = response?.data;
            console.log("result:", result)
            setForgotPwd({ email: ""});
            setUserInput('');
            
            toast.success('Password reset email request sent at your email address!', {
                position: toast.POSITION.TOP_RIGHT
            });
            // navigate(`/dashboard`);
        } catch (error: any) {
            if (error) {
                setResError("Invalid login credentials");
                setShowErrorAlert(true);
                toast.error('Invalid credentials', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setForgotPwd({ email: ""});
            setUserInput('');
        }
    }

    return (
        <>
            <div className='login-main'>
                <div className='seller-acc-main forgot-block'>
                    {<Toaster/>}
                    <Typography variant='h1'>Forgot Password</Typography>
                    {showErrorAlert && (
                      <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {resError}
                      </Alert>
                    )}
                    <form onSubmit={handleForgotPwdRequest} >
                        <Box sx={{
                            border: "1px solid #DEDCDC",
                            backgroundColor: "#FFFFFF"
                        }} className="main-box">
                            <List>
                                <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <label>Email</label>
                                        <TextField
                                            name='email'
                                            variant="outlined"
                                            className='input-field'
                                            type='email'
                                            value={forgotPwd.email}
                                            onChange={(e: any)=>setForgotPwd({email: e.target.value})}
                                            autoComplete='on'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <label>Please type the letters and numbers below</label>
                                        <TextField
                                            name='letters'
                                            variant="outlined"
                                            className='input-field'
                                            value={userInput} 
                                            onChange={handleChange}
                                            required 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
                                        <Box className='capture-block'>
                                            <Box className="capture-img" >
                                            {captcha}
                                            </Box>
                                            <Box className="create-ac-btn" onClick={handleRefresh}>
                                                Reload Captcha
                                            </Box>
                                        </Box>
                                        {/* <Button onClick={handleSubmit}>Check</Button> */}
                                    </Grid>
                                    <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
                                        <Box sx={{ width: '100%', marginTop: '100px' }}>
                                            <Button className='create-btn' type='submit'>
                                                Forgot Password
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </List>
                        </Box>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgotPwd