import React from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/HomePages/Home/Home';
import ReferralFee from './Pages/HomePages/Referral_Fee/ReferralFee';
import Faq from './Pages/HomePages/FAQ/Faq';
import Advertise from './Pages/HomePages/Advertise/Advertise';
import Layout from './Components/Layout/Layout';
import PasswordReset from './Pages/HomePages/Password_Reset/PasswordReset';
import Register from './Pages/HomePages/RegisterSeller/Register';
import Login from './Pages/HomePages/LogIn_Customers/Login';
import Main from './Pages/Main/Main';
import Product from './Pages/Dashboard/Product/Product';
import HomeDashboard from './Pages/Dashboard/HomeDashboard/HomeDashboard';
// import Orders from './Pages/Dashboard/Orders/Orders';
import Returns from './Pages/Dashboard/Returns/Returns';
import Payment from './Pages/Dashboard/Payment/Payment';
import Settings from './Pages/Dashboard/Settings/Settings';
import EditName from './Pages/Dashboard/Settings/EditName';
import EditEmail from './Pages/Dashboard/Settings/EditEmail';
import ChangePassword from './Pages/Dashboard/Settings/ChangePassword';
import EditBankDetails from './Pages/Dashboard/Settings/EditBankDetails';
import EditPickUpDetails from './Pages/Dashboard/Settings/EditPickUpDetails';
import ForgotPwd from './Pages/HomePages/ForgotPWD/ForgotPwd';
import SellerTraning from './Pages/HomePages/SellerTraning/SellerTraning';
import Orders from './Pages/Dashboard/Order/Orders';
// import Orders from './Pages/Dashboard/Orders/OrdersSeperated';

function App() {

  const location = useLocation();

  const isDashboard = location.pathname.startsWith("/dashboard");


  return (
    <>
      {
        isDashboard ? (
          <Main>
            <Routes>
              <Route path="/dashboard" element={<HomeDashboard />} />
              <Route path="/dashboard/product" element={<Product />} />
              <Route path="/dashboard/orders" element={<Orders />} />
              <Route path="/dashboard/returns" element={<Returns />} />
              <Route path="/dashboard/payment" element={<Payment />} />
              <Route path="/dashboard/settings" element={<Settings />} />
              <Route path="/dashboard/settings/edit-name" element={<EditName />} />
              <Route path="/dashboard/settings/edit-email" element={<EditEmail />} />
              <Route path="/dashboard/settings/change-password" element={<ChangePassword />} />
              <Route path="/dashboard/settings/edit-bank-details" element={<EditBankDetails />} />
              <Route path="/dashboard/settings/edit-pickup-details" element={<EditPickUpDetails />} />
            </Routes>
          </Main>
        ) : (
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/referral-fee" element={<ReferralFee />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/advertise" element={<Advertise />} />
              <Route path="/seller-training" element={<SellerTraning />} />
              <Route path="/seller/customer/account/createPassword" element={<PasswordReset />} />
              <Route path="/sell_sa/customer/account/createPassword" element={<PasswordReset />} />
              <Route path="/sell_in/customer/account/createPassword" element={<PasswordReset />} />
              <Route path="/sell_om/customer/account/createPassword" element={<PasswordReset />} />
              <Route path="/register" element={<Register />} />
              <Route path="/seller-login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPwd />} />
            </Routes>
          </Layout>
        )
      }
    </>
  );
}

export default App;
