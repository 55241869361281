import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import OrdersTable from "../../../../Components/Dashboard_Table/OrdersTable";
import { toast } from "react-toastify";
import { OrdersPageQuery } from "../../../../Operations/Queries";
import { CountryContext } from "../../../..";
import { ordersActionMutation } from "../../../../Operations/Mutations";
import Toaster from "../../../../Components/ToastMessage";
import MobileTable from "../mobileTable/MobileTable";

// Define Props type
interface AwbOrderProps {
  menuItems: string[];
  selectedMenuItem: string;
  tabchange:boolean;
}

// Define Order type
interface AWBOrder {
  id: string;
  order: string;
  date: string;
  itemName: string;
  itemPrice: number;
  quantity: number;
  itemTotal: number;
}

const AwbOrders: React.FC<AwbOrderProps> = ({
  menuItems,
  selectedMenuItem,
  tabchange
}) => {
  const [awbOrders, setAwbOrders] = useState<AWBOrder[]>([]);
  const [ordersAwbPageInfo, setOrdersAwbPageInfo] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState({
    pending: 1,
    confirmed: 1,
    awb: 1,
    shipped: 1,
    delivered: 1,
    cancelled: 1,
    archived: 1,
  });

  const loggedInToken = localStorage.getItem("loggedInCust");
  const [search, setSearch] = useState({
    pending: "",
    confirmed: "",
    awb: "",
    shipped: "",
    delivered: "",
    cancelled: "",
    archived: "",
  });

  const array: any = [
    { lable: "5", value: 5 },
    { lable: "10", value: 10 },
    { lable: "15", value: 15 },
  ];
  const { store } = useContext(CountryContext);

  const [pickupRequest] = useMutation(
    ordersActionMutation.ORDER_PICKUP_REQUEST,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const {
    data: OrdersListAwbData,
    refetch: refetchAwbOrders,
    loading: awbLoading,
  } = useQuery(OrdersPageQuery.ORDERS_AWB, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
    variables: {
      orderNumber: search.awb,
      pageSize: pageSize,
      currentPage: currentPage.awb,
    },
  });

  const additionalActionsForAwb = [
    {
      label: "Shipment Status",
      buttonLabel: "Picked Up",
      onClick: async (row: any) => {
        toast.info("Please wait while processing your request!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        // const rowId = row.id;
        const itemId = row.id;
        const orderNumber = row.order;
        const finalPayload = {
          action: "orderAction",
          status: "ship_item",
          order_number: orderNumber,
          item_id: itemId,
        };
        try {
            //eslint-disable-next-line
          const response = await pickupRequest({
            variables: finalPayload,
          });
        //   console.log("response from APi pickp requrest: ", response);

          toast.success("Successfully pickup requested", {
            position: toast.POSITION.TOP_RIGHT,
          });

          refetchAwbOrders();
        } catch (error) {
          toast.error("Error generating AWB", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // console.log("error:", error)
        }
      },
    },
  ];

  useEffect(() => {
    // setOrdersAwbData(OrdersListAwbData?.sellerOrdersList[0]?.items);
    setOrdersAwbPageInfo(OrdersListAwbData?.sellerOrdersList[0]?.pageInfo);
    const transformed = OrdersListAwbData?.sellerOrdersList[0]?.items?.map(
      (order: any, index: any) => ({
        id: order.item_id,
        order: order.order_number,
        waybillNumber: order.waybill_no,
        waybillFile: order.waybill_file,
        date: order.date,
        itemName: order.product_name,
        itemPrice: order.price,
        quantity: order.qty,
        itemTotal: order.sub_total,
        status: order.status,
      })
    );
    setAwbOrders(transformed);
  }, [OrdersListAwbData]);

  const handlePageChange = (_: any, value: number) => {
    setCurrentPage({ ...currentPage, awb: value });
    refetchAwbOrders({
      orderNumber: search.awb,
      pageSize: 10,
      currentPage: value,
    });
  };

  const handlePageSizeChange = (event: any) => {
    const newSize = event.target.value as number;
    setPageSize(newSize);
    setCurrentPage({ ...currentPage, awb: 1 });
    refetchAwbOrders({
      orderNumber: search.awb,
      pageSize: newSize,
      currentPage: 1,
    });
  };

  const handleSearch = () => {
    setCurrentPage({ ...currentPage, awb: 1 });
    refetchAwbOrders({
      orderNumber: search.awb,
      pageSize: 10,
      currentPage: 1,
    });
  };

  useEffect(()=>{
    refetchAwbOrders()
    //eslint-disable-next-line
  },[tabchange])

  const headCellsForAwb = [
    { id: "order", numeric: false, disablePadding: false, label: "Order" },
    {
      id: "waybillNumber",
      numeric: false,
      disablePadding: false,
      label: "AWB Number",
    },
    { id: "date", numeric: false, disablePadding: false, label: "Date" },
    {
      id: "itemName",
      numeric: false,
      disablePadding: false,
      label: "Item Name",
    },
    {
      id: "itemPrice",
      numeric: true,
      disablePadding: false,
      label: "Item Price (AED)",
    },
    { id: "quantity", numeric: true, disablePadding: false, label: "Quantity" },
    {
      id: "itemTotal",
      numeric: true,
      disablePadding: false,
      label: "Item Total (AED)",
    },
    {
      id: "shipmentStatus",
      numeric: false,
      disablePadding: false,
      label: "Shipment Status",
    },
  ];

  return (
    <>
      <Toaster />
      <div className="main">
        <div
          className="btn-group"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <TextField
            placeholder="Search by order number"
            name="search_pending_orders"
            id="search_pending_orders"
            value={search.awb}
            onChange={(e) => setSearch({ ...search, awb: e.target.value })}
            variant="outlined"
            className="search-text-field"
            sx={{
              width: 300,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#888",
                },
              },
            }}
          />
          <Button
            onClick={handleSearch}
            className="search-button"
            sx={{
              height: 50,
              backgroundColor: "#ddd",
              color: "black",
              "&:hover": {
                backgroundColor: "#bbb",
              },
            }}
          >
            <Typography>Search</Typography>
          </Button>
        </div>
        {awbLoading ? (
          <CircularProgress />
        ) : (
          <OrdersTable
            headCells={headCellsForAwb}
            rows={awbOrders}
            ButtonActions={additionalActionsForAwb}
            pageChange={handlePageChange}
            pageType="awb"
            pageNumber={ordersAwbPageInfo?.current_page}
          />
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pt: 5,
          }}
          className="deskPagination"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2">Items per page:</Typography>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              variant="outlined"
              size="small"
            >
              {array?.map((size: any) => (
                <MenuItem key={size} value={size.value}>
                  {size.lable}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Pagination
            count={Math.ceil(80 / pageSize)}
            shape="rounded"
            page={currentPage.awb}
            onChange={handlePageChange}
          />
        </Box>
      </div>
      {menuItems.map(
        (menuItem, index) =>
          selectedMenuItem === menuItem && (
            <div className="mobile-wrapper" key={index}>
              <MobileTable
                headCells={headCellsForAwb}
                rows={awbOrders}
                ButtonActions={additionalActionsForAwb}
                onChange={handlePageChange}
                onRowsPerPageChange={handlePageSizeChange}
                pageSize={pageSize}
                pageIs={currentPage.awb}
              />
            </div>
          )
      )}
    </>
  );
};

export default AwbOrders;
