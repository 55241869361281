import React, { useContext, useEffect, useState } from 'react'
import DashBoardTable from '../../../Components/Dashboard_Table/DashboardTable'
import { Box, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material';
import './Returns.scss';
import MobileTable from '../../../Components/Mobile_Table/MobileTable';
import MobileMenuBar from '../../../Components/Mobile_Menubar/MobileMenubar';
import { useQuery } from '@apollo/client';
import { returnsPageQuery } from '../../../Operations/Queries';
import { CountryContext } from '../../..';

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}
function CustomTabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 1 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}


const Returns = () => {

   const [selectedMenuItem, setSelectedMenuItem] = useState('Before Delivery');
   const [value, setValue] = useState(0);
   const [beforeDeliveryOrders, setBeforeDeliveryOrders] = useState<any[]>([]);
   const [afterDeliveryOrders, setAfterDeliveryOrders] = useState<any[]>([]);

   const loggedInToken = localStorage.getItem("loggedInCust");
   console.log("token from localStorage returnsPage", loggedInToken)

   const {store} = useContext(CountryContext);

   const { data: returnsBeforeDeliveryData, loading: loadingBeforeDeliveryData, error: beforeDeliveryError } = useQuery(returnsPageQuery.RETURNS_BEFORE_DELIVERY, {
      context: {
         headers: {
            Authorization: loggedInToken
               ? `Bearer ${JSON.parse(loggedInToken)}`
               : "",
            Connection: "keep-alive",
            store: store,
         },
      },
   });
   const BeforeDeliveryReturnsData = returnsBeforeDeliveryData?.returnOrders

   const { data: returnsAfterDeliveryData } = useQuery(returnsPageQuery.RETURNS_AFTER_DELIVERY, {
      context: {
         headers: {
            Authorization: loggedInToken
               ? `Bearer ${JSON.parse(loggedInToken)}`
               : "",
            Connection: "keep-alive",
            store: store,
         },
      },
   });
   const AfterDeliveryReturnsData = returnsAfterDeliveryData?.returnOrders

   //headCells for the table
   const headCellsForBeforeDelivery = [
      { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
      { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
      { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (INR)' },
      { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
      { id: 'itemTotal', numeric: true, disablePadding: false, label: 'Item Total (INR)' },
   ];
   const headCellsForAfterDelivery = [
      { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
      { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
      { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (INR)' },
      { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
      { id: 'itemTotal', numeric: true, disablePadding: false, label: 'Item Total (INR)' },
      { id: 'returnDate', numeric: false, disablePadding: false, label: 'Return Date' },
      { id: 'receivedDate', numeric: false, disablePadding: false, label: 'Received Date' },
   ];

   // //rows data for the table
   // const rowsForBeforeDelivery = [
   //    { id: 1, order: 'ORD-SA-00005 237', date: 'Nov 16, 2022 9:47:28 AM', itemName: 'Segafredo - Nespresso Compatible & Compostabl', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1, deliveryDate: 'Nov 16, 2022 9:47:28 AM', returnDate: 'Nov 16, 2022 9:47:28 AM' },
   //    { id: 2, order: 'ORD-SA-00005 238', date: 'Nov 19, 2022 6:30:28 PM', itemName: 'Segafredo - Zanetti Express', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1, deliveryDate: 'Nov 16, 2022 9:47:28 AM', returnDate: 'Nov 16, 2022 9:47:28 AM' },
   //    { id: 3, order: 'ORD-SA-00005 239', date: 'Nov 20, 2022 9:47:28 AM', itemName: 'Segafredo - Capsule MyEspresso', itemPrice: 'AED 1053.00', quantity: 1, itemTotal: 1, deliveryDate: 'Nov 16, 2022 9:47:28 AM', returnDate: 'Nov 16, 2022 9:47:28 AM' },
   //    { id: 4, order: 'ORD-SA-00005 240', date: 'Nov 20, 2022 12:47:28 PM', itemName: 'Segafredo - Macinato Mocha', itemPrice: 'AED 950.00', quantity: 1, itemTotal: 1, deliveryDate: 'Nov 16, 2022 9:47:28 AM', returnDate: 'Nov 16, 2022 9:47:28 AM' },
   // ];
   // const rowsForAfterDelivery = [
   //    { id: 1, order: 'ORD-SA-00005 237', date: 'Nov 16, 2022 9:47:28 AM', itemName: 'Segafredo - Nespresso Compatible & Compostabl', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1, returnDate: 'Nov 16, 2022 9:47:28 AM', receivedDate: 'Nov 16, 2022 9:47:28 AM' },
   //    { id: 2, order: 'ORD-SA-00005 238', date: 'Nov 19, 2022 6:30:28 PM', itemName: 'Segafredo - Zanetti Express', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1, returnDate: 'Nov 16, 2022 9:47:28 AM', receivedDate: 'Nov 16, 2022 9:47:28 AM' },
   // ];

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   const menuItems = [
      'Before Delivery',
      'After Delivery',
   ];

   const handleMenuItem = (itemName: any) => {
      setSelectedMenuItem(itemName);
   };
   console.log("menuItem", selectedMenuItem)

   const getTableHeadCells = (menuItem: string) => {
      switch (menuItem) {
         case 'Before Delivery':
            return headCellsForBeforeDelivery;
         case 'After Delivery':
            return headCellsForAfterDelivery;
      }
   };

   const getTableRows = (menuItem: string) => {
      switch (menuItem) {
         case 'Before Delivery':
            return beforeDeliveryOrders;
         case 'After Delivery':
            return afterDeliveryOrders;
      }
   };

   useEffect(() => {
      const transformed = BeforeDeliveryReturnsData?.map((order: any, index: any) => ({
         id: index,
         order: order.order_number,
         itemName: order.product_name,
         itemPrice: order.price,
         quantity: order.qty,
         itemTotal: order.sub_total,
      }));
      setBeforeDeliveryOrders(transformed)
   }, [BeforeDeliveryReturnsData]);
   console.log("BeforeDelivery Returns Data List", beforeDeliveryOrders)

   useEffect(() => {
      const transformed = AfterDeliveryReturnsData?.map((order: any, index: any) => ({
         id: index,
         order: order.order_number,
         itemName: order.product_name,
         itemPrice: order.price,
         quantity: order.qty,
         itemTotal: order.sub_total,
         returnDate: order.return_date,
         receivedDate: order.received_date
      }));
      setAfterDeliveryOrders(transformed)
   }, [AfterDeliveryReturnsData]);
   console.log("BeforeDelivery Returns Data List", afterDeliveryOrders)

   const errorBeforeDeliveryReturns = beforeDeliveryError?.message;
   console.log("errorBeforeDeliveryReturns:", errorBeforeDeliveryReturns)


   return (
      <div className='returns-page'>
         <MobileMenuBar menuItems={menuItems} onItemClick={handleMenuItem} selectedMenuItem={selectedMenuItem} />
         <Box sx={{ width: '100%' }} className="orders-tabs mobile__view__none">
            <Grid container spacing={3}>
               <Grid item xs={12} sm={12} md={7}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                     <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='order-tabs-head'>
                        <Tab label="Before Delivery" {...a11yProps(0)} className='tabs-title' />
                        <Tab label="After Delivery" {...a11yProps(1)} className='tabs-title' />
                     </Tabs>
                  </Box>
               </Grid>
            </Grid>
            {
               beforeDeliveryError ? (
                  <p className='error-text'>{errorBeforeDeliveryReturns}</p>
               ) : (
                  loadingBeforeDeliveryData ? (
                     <CircularProgress className='loader' />
                  ) : (
                     <>
                        <CustomTabPanel value={value} index={0}>
                           <DashBoardTable headCells={headCellsForBeforeDelivery} rows={beforeDeliveryOrders} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                           <DashBoardTable headCells={headCellsForAfterDelivery} rows={afterDeliveryOrders} />
                        </CustomTabPanel>
                     </>
                  )
               )
            }
         </Box>
         <Box className='mobile__view__block'>
            {menuItems.map((menuItem, index) => (
               selectedMenuItem === menuItem && (
                  // <MobileTable key={index} headCells={getTableHeadCells(menuItem)} rows={getTableRows(menuItem)} />
                  <>
                     {beforeDeliveryError ? (
                        <p className='error-text'>{errorBeforeDeliveryReturns}</p>
                     ) : (
                        loadingBeforeDeliveryData ? (
                           <CircularProgress className='loader' />
                        ) : (
                           <MobileTable key={index} headCells={getTableHeadCells(menuItem)} rows={getTableRows(menuItem)} />
                        )
                     )}
                     {/* <div className='loader'>
                      <CircularProgress className='loader' />
                      </div> */}
                  </>
               )
            ))}
         </Box>
      </div >
   )
}

export default Returns