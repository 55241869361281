import React, { useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import "./Orders.scss";
import MobileMenuBar from "../../../Components/Mobile_Menubar/MobileMenubar";
import "../Product/Products.scss";
import PendingOrder from "./pending/PendingOrder";
import ConfirmedOrder from "./confirmed/ConfirmedOrder";
import AwbOrders from "./awb/AwbOrders";
import ShipedOrder from "./shipped/ShipedOrder";
import DeliveredOrder from "./delivered/DeliveredOrder";
import CancleOrder from "./canclled/CancleOrder";
import ArchivedOrder from "./archived/ArchivedOrder";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Orders = () => {
  //eslint-disable-next-line
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("Pending");
  const [filterOrder, setFilterOrder] = useState("Filter");
  const [column, setColumn] = useState("Columns");
  const [value, setValue] = useState(0);
  const [tabchange,setTabChange]=useState(false)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedMenuItem(event.currentTarget.innerHTML.split('<span')[0]);
    setValue(newValue);
    setTabChange(true)
  };

  const filter = ["Filter", "quarterly", "monthly", "weekly", "daily"];
  const handlefilterChange = (event: SelectChangeEvent) => {
    setFilterOrder(event.target.value as string);
  };

  const columnPeriod = ["Columns", "quarterly", "monthly", "weekly", "daily"];
  const handlecolumnChange = (event: SelectChangeEvent) => {
    setColumn(event.target.value as string);
  };

  const menuItems = [
    "Pending",
    "Confirmed",
    "AWB",
    "Shipped",
    "Delivered",
    "Cancelled",
    "Archived",
  ];

  const handleMenuItem = (itemName: string) => {
    const index = menuItems.indexOf(itemName); 
    if (index !== -1) {
      setValue(index); 
      setSelectedMenuItem(itemName);
      setMenuOpen(false);
    }
  };

  return (
    <div>
      {/* mobile tabs menu  */}
      <MobileMenuBar
        menuItems={menuItems}
        onItemClick={handleMenuItem}
        selectedMenuItem={selectedMenuItem}
      />
      <Box sx={{ width: "100%" }} className="orders-tabs ">
        <Grid className="filter-tabs-block" container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={9}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='order-tabs-head'>
                        <Tab label="Pending" {...a11yProps(0)} className='tabs-title' />
                        <Tab label="Confirmed" {...a11yProps(1)} className='tabs-title' />
                        <Tab label="AWB" {...a11yProps(2)} className='tabs-title' />
                        <Tab label="Shipped" {...a11yProps(3)} className='tabs-title' />
                        <Tab label="Delivered" {...a11yProps(4)} className='tabs-title' />
                        <Tab label="Cancelled" {...a11yProps(5)} className='tabs-title' />
                        <Tab label="Archived" {...a11yProps(6)} className='tabs-title' />
                     </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <div className="order-filter-section">
              <FormControl className="select-form">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterOrder}
                  label="Yearly"
                  onChange={handlefilterChange}
                >
                  {filter.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item}
                      className="menuitem-select"
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="select-form">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={column}
                  label="Yearly"
                  onChange={handlecolumnChange}
                >
                  {columnPeriod.map((item: any, index: any) => (
                    <MenuItem
                      key={index}
                      value={item}
                      className="menuitem-select"
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
        <Box>
          <>
            <CustomTabPanel value={value} index={0}>
              <PendingOrder
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ConfirmedOrder
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <AwbOrders
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <ShipedOrder
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <DeliveredOrder
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <CancleOrder
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
              <ArchivedOrder
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
              />
            </CustomTabPanel>
          </>
        </Box>
      </Box>
    </div>
  );
};

export default Orders;
