import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {  useQuery } from "@apollo/client";
import OrdersTable from "../../../../Components/Dashboard_Table/OrdersTable";
import { OrdersPageQuery } from "../../../../Operations/Queries";
import { CountryContext } from "../../../..";
import Toaster from "../../../../Components/ToastMessage";
import MobileTable from "../mobileTable/MobileTable";

// Define Props type
interface CancleOrderProps {
  menuItems: string[];
  selectedMenuItem: string;
  tabchange: boolean;
}

// Define Order type
interface CancleOrderData {
  id: string;
  order: string;
  date: string;
  itemName: string;
  itemPrice: number;
  quantity: number;
  itemTotal: number;
}

const CancleOrder: React.FC<CancleOrderProps> = ({
  menuItems,
  selectedMenuItem,
  tabchange,
}) => {
    const [cancelledOrders, setCancelledOrders] = useState<CancleOrderData[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState({
    pending: 1,
    confirmed: 1,
    awb: 1,
    shipped: 1,
    delivered: 1,
    cancelled: 1,
    archived: 1,
  });

  const loggedInToken = localStorage.getItem("loggedInCust");
  const [search, setSearch] = useState({
    pending: "",
    confirmed: "",
    awb: "",
    shipped: "",
    delivered: "",
    cancelled: "",
    archived: "",
  });

  const array: any = [
    { lable: "5", value: 5 },
    { lable: "10", value: 10 },
    { lable: "15", value: 15 },
  ];
  const { store } = useContext(CountryContext);

  const { data: OrdersListCancelledData, refetch: refetchCancelledOrders ,loading:cancleOrderLoading} = useQuery(OrdersPageQuery.ORDERS_CANCELLED, {
    context: {
       headers: {
          Authorization: loggedInToken
             ? `Bearer ${JSON.parse(loggedInToken)}`
             : "",
          Connection: "keep-alive",
          store: store,
       },
    },
    variables: {
       orderNumber: search.cancelled,
       pageSize,
       currentPage: currentPage.cancelled,
    }
 });
 const ordersCancelledData = OrdersListCancelledData?.sellerOrdersList[0]?.items
 const ordersCancelledPageInfo = OrdersListCancelledData?.sellerOrdersList[0]?.pageInfo

  useEffect(() => {
    const transformed = ordersCancelledData?.map((order: any, index: any) => ({
       id: order.item_id,
       order: order.order_number,
       waybillNumber: order.waybill_no,
       waybillFile: order.waybill_file,
       date: order.date,
       itemName: order.product_name,
       itemPrice: order.price,
       quantity: order.qty,
       itemTotal: order.sub_total,
    }));
    setCancelledOrders(transformed)
 }, [ordersCancelledData]);

  const handlePageChange = (_: any, value: number) => {
    setCurrentPage({ ...currentPage, cancelled: value });
    refetchCancelledOrders({
      orderNumber: search.cancelled,
      pageSize: 10,
      currentPage: value,
    });
  };

  const handlePageSizeChange = (event: any) => {
    const newSize = event.target.value as number;
    setPageSize(newSize);
    setCurrentPage({ ...currentPage, cancelled: 1 });
    refetchCancelledOrders({
      orderNumber: search.cancelled,
      pageSize: newSize,
      currentPage: 1,
    });
  };

  const handleSearch = () => {
    setCurrentPage({ ...currentPage, cancelled: 1 });
    refetchCancelledOrders({
      orderNumber: search.cancelled,
      pageSize: 10,
      currentPage: 1,
    });
  };

  useEffect(() => {
    refetchCancelledOrders();
    //eslint-disable-next-line
  }, [tabchange]);

  const headCellsForCancelled = [
    { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
    { id: 'waybillNumber', numeric: false, disablePadding: false, label: 'AWB Number' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
    { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (AED)' },
    { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
    { id: 'itemTotal', numeric: true, disablePadding: false, label: 'Item Total (AED)' },
 ];

  return (
    <>
      <Toaster />
      <div className="main">
        <div
          className="btn-group"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <TextField
            placeholder="Search by order number"
            name="search_pending_orders"
            id="search_pending_orders"
            value={search.cancelled}
            onChange={(e) => setSearch({ ...search, cancelled: e.target.value })}
            variant="outlined"
            className="search-text-field"
            sx={{
              width: 300,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#888",
                },
              },
            }}
          />
          <Button
            onClick={handleSearch}
            className="search-button"
            sx={{
              height: 50,
              backgroundColor: "#ddd",
              color: "black",
              "&:hover": {
                backgroundColor: "#bbb",
              },
            }}
          >
            <Typography>Search</Typography>
          </Button>
        </div>
        {cancleOrderLoading ? (
          <CircularProgress />
        ) : (
          <OrdersTable
            headCells={headCellsForCancelled}
            rows={cancelledOrders}
            pageChange={handlePageChange}
            pageType="cancelled"
            pageNumber={ordersCancelledPageInfo?.current_page}
          />
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pt: 5,
          }}
          className="deskPagination"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2">Items per page:</Typography>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              variant="outlined"
              size="small"
            >
              {array?.map((size: any) => (
                <MenuItem key={size} value={size.value}>
                  {size.lable}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Pagination
            count={Math.ceil(80 / pageSize)}
            shape="rounded"
            page={currentPage.cancelled}
            onChange={handlePageChange}
          />
        </Box>
      </div>
      {menuItems.map(
        (menuItem, index) =>
          selectedMenuItem === menuItem && (
            <div className="mobile-wrapper" key={index}>
              <MobileTable
                headCells={headCellsForCancelled}
                rows={cancelledOrders}
                // pageChange={handlePageChange}
                onChange={handlePageChange}
                onRowsPerPageChange={handlePageSizeChange}
                pageSize={pageSize}
                pageIs={currentPage.cancelled}
              />
            </div>
          )
      )}
    </>
  );
};

export default CancleOrder;
