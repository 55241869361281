import { Box, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import "./Payment.scss"
import DashBoardTable from '../../../Components/Dashboard_Table/DashboardTable';
import MobileTable from '../../../Components/Mobile_Table/MobileTable';
import MobileMenuBar from '../../../Components/Mobile_Menubar/MobileMenubar';
import { useQuery } from '@apollo/client';
import { paymentsPageQuery } from '../../../Operations/Queries';
import { CountryContext } from '../../..';


interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}
function CustomTabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3, padding: "0px" }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}

const Payment = () => {

   const [selectedMenuItem, setSelectedMenuItem] = useState('Unpaid');
   const [value, setValue] = useState(0);
   const [unpaidPayment, setUnpaidPayment] = useState<any[]>([]);
   const [paidPayment, setpaidPayment] = useState<any[]>([]);

   const loggedInToken = localStorage.getItem("loggedInCust");

   const {store} = useContext(CountryContext);

   const { data: paymentDataUnpaid, loading: paymentUnpaidLoading, error: errorUnpaidPayment } = useQuery(paymentsPageQuery.PAYMENTS_UNPAID, {
      context: {
         headers: {
            Authorization: loggedInToken
               ? `Bearer ${JSON.parse(loggedInToken)}`
               : "",
            Connection: "keep-alive",
            store: store,
         },
      },
   });

   const unpaidPaymentData = paymentDataUnpaid?.sellerPayments
   console.log("UnpaidPaymentData", unpaidPaymentData)

   const unpaidPaymentError = errorUnpaidPayment?.message;
   console.log("errorBeforeDeliveryReturns:", unpaidPaymentError)

   const { data: paymentDataPaid } = useQuery(paymentsPageQuery.PAYMENTS_PAID, {
      context: {
         headers: {
            Authorization: loggedInToken
               ? `Bearer ${JSON.parse(loggedInToken)}`
               : "",
            Connection: "keep-alive",
            store: store,
         },
      },
   });

   const paidPaymentData = paymentDataPaid?.sellerPayments
   console.log("paidPaymentData", paidPaymentData)

   //headCells for the table
   const headCellsForUnpaid = [
      { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
      { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
      { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (INR)' },
      { id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity' },
      { id: 'itemTotal', numeric: false, disablePadding: false, label: 'Item Total (INR)' },
      { id: 'comission', numeric: false, disablePadding: false, label: 'Comission' },
   ];
   const headCellsForPaid = [
      { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
      { id: 'price', numeric: true, disablePadding: false, label: 'Price (INR)' },
      { id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity' },
      { id: 'subTotal', numeric: false, disablePadding: false, label: 'Sub Total (INR)' },
      { id: 'comission', numeric: false, disablePadding: false, label: 'Comission' },
      { id: 'payout', numeric: false, disablePadding: false, label: 'Payout' },
      { id: 'payoutId', numeric: false, disablePadding: false, label: 'Payout ID' },
      { id: 'download', numeric: false, disablePadding: false, label: 'Download' },
   ];

   //rows data for the table
   //eslint-disable-next-line
   const rowsForUnpaid = [
      { id: 1, Order: '#23695', date: 'Nov 16, 2022 9:47:28 AM', itemName: 'Segafredo - Nespresso Compatible & Compostabl', amount: 'AED 816.00', status: 'Unpaid' },
      { id: 2, Order: '#25692', date: 'Nov 19, 2022 6:30:28 PM', itemName: 'Segafredo - Zanetti Express', amount: 'AED 816.00', status: 'Unpaid' },
      { id: 3, Order: '#454862', date: 'Nov 20, 2022 9:47:28 AM', itemName: 'Segafredo - Capsule MyEspresso', amount: 'AED 1053.00', status: 'Unpaid' },
      { id: 4, Order: '#25863', date: 'Nov 20, 2022 12:47:28 PM', itemName: 'Segafredo - Macinato Mocha', amount: 'AED 950.00', status: 'Unpaid' },
   ];

   //eslint-disable-next-line
   const rowsForPaid = [
      { id: 1, Order: '#23695', date: 'Nov 16, 2022 9:47:28 AM', itemName: 'Segafredo - Nespresso Compatible & Compostabl', amount: 'AED 816.00', status: 'Paid' },
      { id: 2, Order: '#25692', date: 'Nov 19, 2022 6:30:28 PM', itemName: 'Segafredo - Zanetti Express', amount: 'AED 816.00', status: 'Paid' },
   ];

   const additionalActionsForUnpaid = [
      {
         label: 'Request to Pay',
         buttonLabel: 'Pay',
         onClick: (row: any) => {
            console.log('Confirm clicked for row:', row);
         }
      },
   ];

   useEffect(() => {
      const transformed = unpaidPaymentData?.map((order: any, index: any) => ({
         id: index,
         order: order.order_number,
         itemName: order.product_name,
         itemPrice: order.item_price,
         quantity: order.qty,
         itemTotal: order.sub_total,
         comission: order.comission,
      }));
      setUnpaidPayment(transformed);
   }, [unpaidPaymentData]);

   useEffect(() => {
      const transformed = paidPaymentData?.map((order: any, index: any) => ({
         id: index,
         order: order.order_number,
         price: order.item_price,
         quantity: order.qty,
         subTotal: order.sub_total,
         comission: order.comission,
         payout: order.payout,
         payoutId: order.payout_id,
         download: order.download
      }));
      setpaidPayment(transformed);
   }, [paidPaymentData]);

   const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   const menuItems = [
      'Unpaid',
      'Paid',
   ];

   const handleMenuItem = (itemName: any) => {
      setSelectedMenuItem(itemName);
   };

   console.log("menuItem selected", selectedMenuItem);

   const getTableHeadCells = (menuItem: string) => {
      switch (menuItem) {
         case 'Unpaid':
            return headCellsForUnpaid;
         case 'Paid':
            return headCellsForPaid;
      }
   };

   const getTableRows = (menuItem: string) => {
      switch (menuItem) {
         case 'Unpaid':
            return unpaidPayment;
         case 'Paid':
            return paidPayment;
      }
   };

   //eslint-disable-next-line
   const getAdditionalActions = (menuItem: string) => {
      switch (menuItem) {
         case 'Unpaid':
            return additionalActionsForUnpaid;
      }
   };

   return (
      <div className='payment-page'>
         <MobileMenuBar menuItems={menuItems} onItemClick={handleMenuItem} selectedMenuItem={selectedMenuItem} />
         <Box sx={{ width: '100%' }} className="orders-tabs mobile__view__none">
            <Grid container spacing={2}>
               <Grid item xs={12} sm={12} md={12}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                     <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example" className='order-tabs-head'>
                        <Tab label="Unpaid" {...a11yProps(0)} className='tabs-title' />
                        <Tab label="Paid" {...a11yProps(1)} className='tabs-title' />
                     </Tabs>
                  </Box>
               </Grid>
               <Grid item xs={12} sm={12} md={7} className='title table-title'>
                  <Typography variant='h5'>Invoices</Typography>
               </Grid>
            </Grid>
            {
               errorUnpaidPayment ? (
                  <p className='error-text'>{unpaidPaymentData}</p>
               ) : (
                  paymentUnpaidLoading ? (
                     <CircularProgress className='loader' />
                  ) : (
                     <>
                        <CustomTabPanel value={value} index={0}>
                           <DashBoardTable headCells={headCellsForUnpaid} rows={unpaidPayment} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                           <DashBoardTable headCells={headCellsForPaid} rows={paidPayment} />
                        </CustomTabPanel>
                     </>
                  )
               )
            }
         </Box>
         <Box className='mobile__view__block'>
            <Box className='title table-title m-view-title'>
               <Typography variant='h5'>Invoices</Typography>
            </Box>
            {menuItems.map((menuItem, index) => (
               selectedMenuItem === menuItem && (
                  <MobileTable key={index} headCells={getTableHeadCells(menuItem)} rows={getTableRows(menuItem)} />
               )
            ))}
         </Box>
      </div >
   )
}

export default Payment